import Testimonials from "@/components/index/sections/Testimonials/Testimonials"
import React from "react"
import IntroSlick from "../components/index/IntroSlick/IntroSlick"
import { NextCourseSection } from "../components/index/NextCourseCard/NextCourseCard"
// import { oc } from "ts-optchain"
import ContactUs from "../components/index/sections/ContactUs/ContactUs"
import Courses from "../components/index/sections/Courses/Courses"
import Stats from "../components/index/sections/Stats/Stats"
import WhyChooseUs from "../components/index/sections/WhyChooseUs"
import SEO from "../components/seo"
import Layout from "../components/shared/Layout/layout"

const IndexPage = () => {
  return (
    <Layout fluid={true}>
      <SEO
        description="Sme autoškolou, ktorá ma jednu z najvyšších úspešností na skúškach v okrese Zvolen.Vykonávame výcvik skupiny B ku ktorému získaš aj podskupiny B1 a AM. Ak si chceš preveriť svoje skúsenosti ponúkame kondičné jazdy skupiny B a taktiež rekvalifikačný kurz skupiny B."
        title="Domov"
        keywords="autoškola Hron, autoškola hron, autoškola Zvolen, vodičák Zvolen, najlepšia autoškola Zvolen, rekvalifikačný kurz Zvolen, kondičné jazdy Zvolen"
      />

      <IntroSlick/>

      <NextCourseSection />
      <WhyChooseUs />
      <Courses />
      <ContactUs />
      <Stats />
      <Testimonials />
    </Layout>
  )
}
export default IndexPage
