import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import styled from "styled-components"
import Container from "../../../shared/Layout/components/Container/container"
import { Section } from "../../../shared/Layout/components/Section/Section"
import TestimonialCard from "./components/TestimonialCard"
import { useTestimonials } from "./hooks/useTestimonials"
import { testimonialsArray } from "./testimonials-array"

const TestimonialsSection = styled(Section)`
  padding-bottom: 100px;
`

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img className="slick-next-icon" src="arrow.svg" alt="šípka ikona ďalší slide" width="20" height="33" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img className="slick-prev-icon" src="arrow.svg" alt="šípka ikona predchádzajúci slide" width="20" height="33" />
    </div>
  )
}

interface Props {}

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
  margin-bottom: 40px;
`

const Content = styled.div`
  margin: 0 40px;
  @media (max-width: 600px) {
    margin: 0 5px;
  }

  .slick-list {
    padding: 50px 0;
  }

  /* .slick-slide {
    max-width: 350px;
    margin: 20px;

    @media (max-width: 1260px) {
      max-width: 300px;
    }
  } */
  .slider-item {
    width: 90% !important;
    margin: 0 auto;
    justify-content: center;
    /* padding: 0 20px ; */

    @media (max-width: 1260px) {
      max-width: 300px;
    }

    @media (max-width: 750px) {
      max-width: 350px;
    }
    @media (max-width: 600px) {
      max-width: 250px;
    }
  }

  .slick-arrow {
    &::before {
      content: none !important;
    }
  }

  .slick-next-icon {
    transform: rotate(180deg);
  }

  .slick-prev {
    left: -60px;
  }

  .slick-next {
    right: -60px;
  }

  .slick-slide > div {
    display: flex;
  }

  .slick-slide,
  .slick-slide * {
    outline: none !important;
  }

  .slick-dots li.slick-active button::before {
    color: ${({ theme }) => theme.color.primary};
    box-shadow: 0px 2px 6px rgb(63 73 159 / 30%);
    opacity: 1;
  }
  .slick-dots li button:before {
    font-size: 10px;
    background: ${({ theme }) => theme.color.primary};
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }

  .slick-dots li {
    margin: 0 10px;
  }
`
export const Testimonials: React.FC<Props> = (props) => {
  var settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: testimonialsArray.length > 2 ? 3 : testimonialsArray.length,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    dots: true,
    className: "slides",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1070,
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  }

  const testimonials = useTestimonials()
  console.log(testimonials)

  return (
    <TestimonialsSection>
      <Container fluid={false}>
        <Header data-sal-duration="500" data-sal="slide-up" data-sal-easing="ease-in" data-sal-delay="150">
          <span className="upTitle">Recenzie</span>
          <h2> Čo o nás povedali absolventi</h2>
        </Header>
        <Content>
          <div data-sal-duration="500" data-sal="slide-up" data-sal-easing="ease-in" data-sal-delay="150">
            <Slider {...settings}>
              {testimonials.map((item, index) => (
                <div key={index} className="slider-item">
                  <TestimonialCard {...item} />
                </div>
              ))}
            </Slider>
          </div>
        </Content>
      </Container>
    </TestimonialsSection>
  )
}

export default Testimonials
