import { Grid } from "@material-ui/core"
import React from "react"
import CounterCard from "./components/CounterCard/CounterCard"
import Container from "../../../shared/Layout/components/Container/container"
import { Section } from "../../../shared/Layout/components/Section/Section"
import styled from "styled-components"
import { statsArray } from "./statsArray"
import { Stripe } from "../../../shared/Layout/components/Stripe/Stripe"

const StatsSection = styled(Section)`
  margin-bottom: 30px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0;
  }
`
const StyledGrid = styled(Grid)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 30px !important;
  }
`

const StyledStripe = styled(Stripe)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-height: 100%;
    padding-bottom: 30px;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
`

interface Props {}

const Stats: React.FC<Props> = (props) => {
  return (
    <StatsSection>
      <StyledStripe>
        <Container fluid={false}>
          <Grid container spacing={5} id="cisla">
            {statsArray.map((item, index) => (
              <StyledGrid key={index} item lg={3} md={3} sm={6} xs={12}>
                <CounterCard
                  {...item}
                />
              </StyledGrid>
            ))}
          </Grid>
        </Container>
      </StyledStripe>
    </StatsSection>
  )
}

export default Stats
