import { Button } from "@material-ui/core/"
import dateFormat from "dateformat"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { muiTheme } from "../../../lib/theme"
import Card from "../../shared/Card"
import Container from "../../shared/Layout/components/Container/container"
import { Section } from "../../shared/Layout/components/Section/Section"

export enum CourseTypes {
  courseB = "kurzB",
  firstAid = "prvaPomocs",
}

const DateHeading = styled.h2`
  color: ${({ theme }) => theme.color.secondary};
  margin-top: 20px;
  margin-bottom: 10px;
`
const Time = styled.h2`
  margin-top: 10px;
  margin-bottom: 20px;
`
const Place = styled.a`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 100;
  margin: 30px 0;
  display: block;
`
const CardWrapper = styled.div`
  padding: 50px 0;

  ${muiTheme.breakpoints.down("md")} {
    padding: 0px 0;
  }
`
const StyledCard = styled(Card)`
  max-width: 750px;
  margin: 0 auto;
`
const NoCourseH = styled.h2`
  /* color: ${({ theme }) => theme.color.gray}; */
  font-size: 28px;
`

const NoCourse = styled.div`
  padding: 20px 0;
`
export interface NextCourseSectionProps extends React.HTMLAttributes<HTMLDivElement> {}

interface StrapiCourse {
  place: string
  date: Date
  type: string
}

const getTheMostUpcomingCourse = (courses: StrapiCourse[]) => {
  return courses.filter((course) => new Date(course.date) > new Date())[0]
}

function parseDateISOString(s) {
  let ds = s.split(/\D+/).map((s) => parseInt(s))
  ds[1] = ds[1] - 1 // adjust month
  return new Date(...ds)
}

const NextCourseSectionInner = (props: NextCourseSectionProps) => {
  const {
    allStrapiRealCourse: { courses },
  } = useStaticQuery(graphql`
    query upcomingCoursesQuery {
      allStrapiRealCourse(sort: { order: ASC, fields: date }) {
        courses: nodes {
          date
          place
          type
        }
      }
    }
  `)

  const upcommingCourse = getTheMostUpcomingCourse(
    courses.filter((course) => {
      const typeMatches = course.type === CourseTypes.courseB
      const dateIsHigher = new Date(course.date) > new Date()
      return typeMatches && dateIsHigher
    })
  )

  return (
    <Section className={props.className}>
      <Container fluid={false}>
        <CardWrapper data-sal-duration="250" data-sal="fade" data-sal-easing="ease-in" data-sal-delay="200">
          <StyledCard>
            {upcommingCourse ? (
              <div className="upcommingCourse">
                <h2>Najbližší kurz otvárame</h2>
                <h3>Vodičské oprávnenie skupiny B</h3>
                <DateHeading>{dateFormat(upcommingCourse.date, "dd.mm.yyyy")}</DateHeading>
                <Time>{dateFormat(upcommingCourse.date, "HH:MM")}</Time>
                <div>
                  <Place
                    target="_blank"
                    href="https://www.google.com/maps/place/Centrum+vo%C4%BEn%C3%A9ho+%C4%8Dasu+Domino/@48.5764144,19.1352949,17z/data=!4m12!1m6!3m5!1s0x47153821cb360345:0xc2ffef16e0193462!2zQ2VudHJ1bSB2b8S-bsOpaG8gxI1hc3UgRG9taW5v!8m2!3d48.5764109!4d19.1374836!3m4!1s0x47153821cb360345:0xc2ffef16e0193462!8m2!3d48.5764109!4d19.1374836"
                    rel="noopener noreferrer"
                  >
                    Centrum voľného času Domino
                    <br />
                    Bela IV. 1567/6
                    <br />
                    960 01 Zvolen
                  </Place>
                </div>
                <Link to="/kurzy">
                  <Button style={{ padding: "14px 28px" }} variant="contained" color="primary">
                    Ďalšie kurzy
                  </Button>
                </Link>
              </div>
            ) : (
              <>
                <NoCourse>
                  <NoCourseH>Dátum najbližšieho kurzu ešte nebol pridaný.</NoCourseH>
                  <span>Pre viac informácií nás kontaktujte.</span>
                </NoCourse>
                <Link to="/kontakt">
                  <Button style={{ padding: "14px 28px" }} variant="contained" color="primary">
                    Opýtať sa
                  </Button>
                </Link>
              </>
            )}
          </StyledCard>
        </CardWrapper>
      </Container>
    </Section>
  )
}

export const NextCourseSection = styled(NextCourseSectionInner)``
