import React, { useState } from "react"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import Card from "../../../../../shared/Card"
import styled from "styled-components"

export interface Props {
  number: number
  label: string
  suffix?: string
}

const Label = styled.h3``

const CountUpHeading = styled.h2<{
  ref: string
}>`
  text-align: center;
`
const StyledCard = styled(Card)`
  padding: 20px;
  height: 100%;
`

const CounterCard: React.FC<Props> = (props) => {
  const [countedUp, setCountedUp] = useState<boolean>(false)
  const setCountedUpToTrue = () => {
    setCountedUp(true)
  }

  return (
    <div className="counter-card" style={{ height: "100%" }}>
      <StyledCard>
        <CountUp
          end={props.number}
          redraw={true}
          useEasing={true}
          duration={5}
          separator=" "
          onEnd={setCountedUpToTrue}
          {...props}
        >
          {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
              <CountUpHeading ref={countUpRef}> </CountUpHeading>
            </VisibilitySensor>
          )}
        </CountUp>
        <Label>{props.label}</Label>
      </StyledCard>
    </div>
  )
}

export default CounterCard
