import React from "react"
import Card from "../../../../shared/Card"
import Rating from "@material-ui/lab/Rating"
import styled from "styled-components"

export interface Props {
  name: string
  rating: number
  when: string
  text: string
}

const InnerTestimonialCard = styled.div`
  height: 350px;
  overflow: hidden;
  text-align: left;
`
const Reviewer = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 4px;
  color: #3f499f;
  text-transform: initial;
  text-align: left;
  margin-bottom: 15px;
`
const Subheading = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
`
const When = styled.span`
  margin-left: 20px;
  color: #676767;
`
const TestimonialHeader = styled.div`
  margin-bottom: 12px;
`
const StyledCard = styled(Card)`
  box-shadow: ${({ theme }) => theme.color.primary};
  padding: 30px;
`

const TestimonialCard: React.FC<Props> = (props) => {
  return (
    <StyledCard>
      <InnerTestimonialCard>
        <TestimonialHeader>
          <Reviewer>{props.name}</Reviewer>
          <Subheading>
            <Rating
              size="small"
              name="read-only"
              value={props.rating}
              readOnly
              precision={0.5}
            />
            <When>{props.when}</When>
          </Subheading>
        </TestimonialHeader>
        <p>{props.text}</p>
      </InnerTestimonialCard>
    </StyledCard>
  )
}

export default TestimonialCard
