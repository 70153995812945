import {Grid} from "@material-ui/core"
import Container from "@material-ui/core/Container/Container"
// import Container from "../../shared/Layout/components/Container/container"
import {graphql, useStaticQuery} from "gatsby"
import React from "react"
import styled from "styled-components"
import {Section} from "../../shared/Layout/components/Section/Section"
import {theme} from "../../shared/Theme/Theme"
import {CustomSlide} from "@/components/shared/ReactSlick/CustomSlide";

export interface Props {
}

const IntroSlickSection = styled(Section)``

const IntroSlickDots = styled.ul`
  text-align: left;
  color: rgba(0, 0, 0, 0.35);
  font-size: 20px;
  line-height: 23px;
  font-family: ${theme.font.secondary};

  .slick-active {
    color: #3f3e3e !important;
    font-size: 25px;
    line-height: 28px;
  }
`

export const appendedDots = (dots) => {
  return <IntroSlickDots>{dots}</IntroSlickDots>
}

const IntroSlick = (props: Props) => {
    const {
      strapiSliderIntro: {Slides},
    } = useStaticQuery(graphql`
        query Slider {
            strapiSliderIntro {
                Slides {
                    Title
                    Subtitle
                    ButtonText
                    ButtonUrl
                    Image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: TRACED_SVG)
                            }
                        }
                    }
                }
            }
        }
    `)

  const slide = Slides[0]
  return (
    <section className="py-4 md:py-8 lg:py-16">
      <IntroSlickSection>
        <Container>
          <Grid>
            <CustomSlide
              key={slide.Title}
              title={slide.Title}
              subtitle={slide.Subtitle}
              buttonText={slide.ButtonText}
              buttonUrl={slide.ButtonUrl}
              image={slide.Image}
            />
          </Grid>
        </Container>
      </IntroSlickSection>
    </section>
  )
}
export default IntroSlick
