export const returnNumberOfYears = () => {
  const now = new Date().getFullYear()
  const startYear = 1996
  const numberOfYears = now - startYear
  return numberOfYears
}

export const statsArray = [
  {
    number: returnNumberOfYears(),
    label: "rokov skúseností",
  },
  {
    number: 177500,
    label: "hodín výučby ",
  },
  {
    number: 2500,
    label: "absolventov",
  },
  {
    number: 1500000,
    label: "kilomentrov",
  },
]
