import React from "react"
import styled from "styled-components"
import checkIcon from "../../images/icon/select.svg"

interface Props {
  label: string
}

const CheckIcon = styled.img`
  width: 25px;
  height: 25px;
  display: block;
  margin-right: 20px;
`
const Label = styled.span`
  color: ${({ theme }) => theme.color.primary};
  max-width: 300px;
  text-align: left;
`
const InnerCheckLabel = styled.div`
  display: flex;
  align-items: center;
  /* margin-bottom: 30px; */
`

const CheckLabel: React.FC<Props> = (props) => {
  return (
    <InnerCheckLabel>
      <CheckIcon src={checkIcon} alt="icon" width="25" height="25"></CheckIcon>
      <Label>{props.label}</Label>
    </InnerCheckLabel>
  )
}

export default CheckLabel
