export const testimonialsArray = [
  {
    name: "Jozef",
    rating: 5,
    when: "dec. 2020",
    text: "Odporúčam na 100 percent, najlepšia autoškola vo Zvolene. Ochotní inštruktori, trpezlivosť, profesionalita.",
  },
  {
    name: "Ema",
    rating: 5,
    when: "nov. 2020",
    text:
      "Výborná autoškola, naozaj odporúčam!!! Veľmi príjemná atmosféra, skvelý prístup inštruktorov, ktorí poctivo pripravia na skúšky, na ktoré pôjdete s dobrým pocitom. Úplná spokojnosť, Odporúčam všetkým, ktorí sa nevedia rozhodnúť. Ďakujem veľmi pekne Autoškole HRON.",
  },
  {
    name: "Martina",
    rating: 5,
    when: "nov. 2020",
    text:
      "Výborná autoškola, pokojná atmosféra, vždy som chodila na jazdy s radosťou, seriózny prístup, veľmi ochotní, príjemní a taktiež aj vtipní inštruktori. Na skúšky vás poctivo pripravia a pôjdete na ne s dobrým pocitom.Autoškole HRON veľmi pekne ďakujem a určite ju všetkým odporúčam :)",
  },
  {
    name: "Sarah",
    rating: 5,
    when: "okt. 2020",
    text:
      "Priateľský prístup, pohodová atmosféra, žiadne stresy, sranda počas jázd, moderné auto, ochota a hlavne 150%-ná pripravenosť na skúšky. Dohoda jázd taktiež výborná, inštruktor sa vždy prispôsobil. Odporúčam každému, kto sa nevie rozhodnúť. A ja ďakujem za výborný prístup, vedomosti a zručnosti Autoškole HRON. Veľká spokojnosť, odporúčam!!!",
  },

  {
    name: "Martina",
    rating: 5,
    when: "05.01.2020",
    text:
      "Pre mňa Autoškola Hron bola najlepšia voľba. Jazdy úplne skvelé, žiadny stres a hlavne priateľský prístup inštruktorov. Na skúšky budete 100% pripravení a pôjdete na ne s dobrým pocitom. Môžem len odporúčať a poďakovať Autoškole Hron za skvelý prístup.😍",
  },

  {
    name: "Lucia",
    rating: 5,
    when: "31.10.2019",
    text:
      "Isto odporúčam ísť do tejto autoškoly 😊 Inštruktori sú priateľský, chápavý a vedia veľmi dobre naučiť. Ja som úplne spokojná, podľa mňa najlepšia autoškola vo Zvolene. 😊",
  },
  {
    name: "Filip",
    rating: 5,
    when: "17.06.2019",
    text: "Perfektný prístup a trpezlivý a vtipný inštruktori. Odporúčam!.",
  },
  {
    name: "Martin ",
    rating: 5,
    when: "21.03.2019",
    text:
      "Autoškolu Hron vrelo odporúčam. Osobne som veľmi spokojný s výberom autoškoly. Inštruktor je veľmi ohľaduplný a stále poradí keď niečo neviete. Jazdy sú zábavné a hlavne sa ľahko a rýchlo naučíte kvalitne šoférovať. Tak ak by ste nevedeli akú autoškolu si vybrať, neváhajte si vybrať práve túto.",
  },

  {
    name: "Timotej",
    rating: 5,
    when: "29.07.2018",
    text: "Super autoškola a prístup inštruktorov, vždy sa dá dohodnúť na jazde.",
  },

  {
    name: "Petra",
    rating: 5,
    when: "02.05.2018",
    text:
      "Skvelá autoškola. Ukážkový a priateľský prístup, nikdy som nemala pocit, že sa niečo nenaučím, pretože trpezlivosť inštruktorov je obdivuhodná. Inštruktori sú skvelí, či už po edukatívnej stránke alebo ako ľudia. Odporúčam! :)",
  },
]
