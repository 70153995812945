import React from "react"
import Grid from "@material-ui/core/Grid"
import Card from "../../../shared/Card"
import { Button } from "@material-ui/core"
import { Link } from "gatsby"
import Container from "../../../shared/Layout/components/Container/container"
import { Section } from "../../../shared/Layout/components/Section/Section"
import styled from "styled-components"

interface Props {}

const SmallHeading = styled.div`
  margin-bottom: 30px;

  p {
    max-width: 600px;
    text-align: left;
  }
`
const BackSquare = styled.div`
  height: 80%;
  background-color: #f1ebff;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 60%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }
`

const ContactUsSection = styled(Section)`
  position: relative;
  padding: 80px 0;
  margin: 30px 0;
`

const Content = styled.div`
  position: relative;
  z-index: 10;
`
const ContactUs: React.FC<Props> = () => {
  return (
    <ContactUsSection>
      <BackSquare />
      <Content>
        <Container fluid={false}>
          <Grid
            style={{
              padding: "0 20px",
            }}
            container
            spacing={1}
          >
            <Grid item sm={12} xs={12} md={6}>
              <Grid
                item
                sm={12}
                md={8}
                data-sal-duration="500"
                data-sal="fade"
                data-sal-delay="100"
                data-sal-easing="ease-in"
              >
                <span className="upTitle">Napíš nám</span>
                <h2>Kontaktuj nás, radi ti zodpovieme otázky </h2>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <Card
                style={{
                  padding: 40,
                }}
              >
                <Grid
                  item
                  sm={12}
                  md={8}
                  container
                  justify="flex-start"
                  alignItems="center"
                  data-sal-duration="500"
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease-in"
                >
                  <SmallHeading>
                    <p>
                      Ak máš otázky, na ktoré si tu nenašiel odpoveď, ozvi sa
                      nám. Možno práve vďaka nám získaš vodičák! Dáme si záležať
                      aby si dostal našu odpoveď čo najskôr.
                    </p>
                  </SmallHeading>
                  <Link to="/kontakt">
                    <Button
                      color="primary"
                      variant="contained"
                      className="upper"
                    >
                      Napíš nám správu
                    </Button>
                  </Link>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Content>
    </ContactUsSection>
  )
}

export default ContactUs
