import { Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"
import Card from "../../shared/Card"
import CheckLabel from "../../shared/CheckLabel"
import Container from "../../shared/Layout/components/Container/container"
import { Section } from "../../shared/Layout/components/Section/Section"

interface Props {}

const WhyChooseUsSection = styled(Section)``
const Description = styled.p`
  margin-bottom: 40px;
`
const ImageWrapper = styled.div`
  max-width: 100%;
  padding-right: 30px;
`

const WhyChooseUs: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: { eq: "left_car.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <WhyChooseUsSection>
      <Container fluid={false}>
        <Grid container justify="center" alignItems="center">
          <Grid item md={6} data-sal-duration="500" data-sal="fade" data-sal-easing="ease-in" data-sal-delay="650">
            <ImageWrapper>
              <Img fluid={data.image.childImageSharp.fluid} alt="autoškola HRON vozidlo" />
            </ImageWrapper>
          </Grid>
          <Grid item md={6} data-sal-duration="500" data-sal="slide-up" data-sal-easing="ease-in">
            <span className="upTitle">O nás</span>
            <h2 className="section-heading">Prečo ísť k nám</h2>
            <Description className="section-heading">
              Našou snahou je odovzdať ti čo najviac skúseností s vedením motorového vozidla, čo najskôr ťa začleniť do
              „aktívneho života na cestách“, poukázať na časté chyby vodičov a ukázať ti ako sa im treba vyvarovať, aby
              tvoja jazda bola bezpečná, plynulá a bezproblémová. <br /> Okrem dlhoročných a bohatých skúseností ti
              prinášame aj ďalšie dôvody, prečo je Autoškola Hron tou správnou voľbou.
            </Description>
            <Card data-sal-duration="500" data-sal="slide-up" data-sal-easing="ease-in" data-sal-delay="750">
              <Grid className="whychooseus__wrapper" container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CheckLabel label="Individuálny prístup" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CheckLabel label="Možnosť sprostredkovania kurzu prvej pomoci so zľavou" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CheckLabel label="Možnosť platby na splátky bez navýšenia!" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CheckLabel label="Kvalitný výcvik so zľavami pre študentov" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CheckLabel label="Najvyššia úspešnosť na skúškach v okrese Zvolen " />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CheckLabel label="Kolektív kvalitných inštruktorov s dlhoročnými skúsenosťami" />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </WhyChooseUsSection>
  )
}

export default WhyChooseUs
