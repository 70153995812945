import { Grid } from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React  from "react"
import styled from "styled-components"
export interface FluidImage {
  childImageSharp: {
    fluid: FluidObject
  }
}

export const ButtonLink = styled.a`
  color: ${({ theme }) => theme.color.white};
`

const ImageWrapp = styled.div`
  width: 100%;
  max-height: 800px;
  height: 100%;
  img {
    margin: 0 auto;
  }
`

interface SlideProps {
  index?: number
  title?: string
  subtitle?: string
  buttonText: string
  buttonUrl: string
  image: FluidImage
}

export const CustomSlide = (props: SlideProps) => {
  const { index, title, subtitle, buttonText, buttonUrl, image, ...restOfProps } = props

  return (
    <Grid container alignItems="center" justify="center" spacing={10} key={index} data-index={index}  >
      <Grid item md={6} container justify="flex-start" alignItems="center">
        <h2>{title}</h2>
        <p className="text-xl md:text-3xl mb-12">{subtitle}</p>

        <Link to={buttonUrl}>
          <Button style={{}} variant="contained" color="primary">
            {buttonText}
          </Button>
        </Link>
      </Grid>
      <Grid item md={6} container justify="center" alignItems="center">
        <div className="p-8 w-full h-full">
          <ImageWrapp>
            <GatsbyImage key={title} loading={"eager"} alt={"Hero image"}  image={getImage(image.localFile)} />
          </ImageWrapp>
        </div>
      </Grid>
    </Grid>
  )
}
