import { Button } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Card from "../../../../../shared/Card"
import { CourseCard } from "../../Courses"

interface Props {
  course: CourseCard
}

const CustomHeader = styled.div`
  text-align: center;
  margin-bottom: 45px;
`

const Description = styled.div`
  margin: 15px 0;
`
const MainHeading = styled.h2`
  font-size: 24px;
  line-height: 28px;
`
const CustomFooter = styled.div`
  padding: 20px 0;
`
const TypeCard = styled(Card)`
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 30px;
    height: auto;
  }
`

const CourseTypeCard: React.FC<Props> = (props) => {
  return (
    <TypeCard>
      <div className="top">
        <CustomHeader>
          <span className="upTitle">Kurz</span>
          <MainHeading>{props.course.name}</MainHeading>
        </CustomHeader>
        <Description>
          <p>{props.course.description}</p>
        </Description>
      </div>
      <CustomFooter>
        <Link to={"/vycvik/"}>
          <Button variant="outlined" className="upper" color="primary">
            Zistiť viac
          </Button>
        </Link>
      </CustomFooter>
    </TypeCard>
  )
}

export default CourseTypeCard
