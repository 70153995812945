import { graphql, useStaticQuery } from "gatsby"

export interface Testimonial {
  name: string
  rating: number
  when: Date
  text: string
}

/**
 * Provides strapi testimonials
 */
export const useTestimonials = (): Testimonial[] => {
  const {
    strapiHomePage: { reviews },
  } = useStaticQuery(graphql`
    query getReviewsQuery {
      strapiHomePage {
        reviews {
          name
          rating
          text
          date
        }
      }
    }
  `)

  return reviews
}
