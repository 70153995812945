import { Button, Grid } from "@material-ui/core"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { muiTheme } from "../../../../lib/theme"
import Container from "../../../shared/Layout/components/Container/container"
import { Section } from "../../../shared/Layout/components/Section/Section"
import CourseTypeCard from "./components/CourseTypeCard/CourseTypeCard"

interface Props {}

const CoursesSection = styled(Section)``
export interface CourseCard {
  name: string
  description: string
}

const Intro = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
  margin-bottom: 80px;

  ${muiTheme.breakpoints.down("md")} {
    margin-bottom: 40px;
  }
`

const Cards = styled.div``
const Bottom = styled.div`
  display: flex;
  justify-content: center;
`

const Courses: React.FC<Props> = () => {
  const {
    allStrapiCourseType: { courseTypes },
  } = useStaticQuery(graphql`
    query courseTypesQuery {
      allStrapiCourseType {
        courseTypes: nodes {
          name
          description
        }
      }
    }
  `)

  return (
    <>
      <CoursesSection>
        <Container fluid={false}>
          <Intro data-sal-duration="500" data-sal="slide-up" data-sal-easing="ease-in">
            <span className="upTitle">Kurzy</span>
            <h2 className="section-heading">Naučíme ťa jazdiť</h2>
            <p style={{ width: "60%" }} className="section-description">
              Vykonávame výcvik skupiny B ku ktorému získaš aj podskupiny B1 a AM. Ak si chceš preveriť svoje skúsenosti
              ponúkame kondičné jazdy skupiny B a taktiež rekvalifikačný kurz skupiny B.
            </p>
          </Intro>
          <Cards>
            <Grid container spacing={6}>
              {courseTypes.map((course, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  md={4}
                  sm={12}
                  data-sal-duration="650"
                  data-sal="slide-up"
                  data-sal-easing="ease-in"
                  data-sal-delay={`${index + 1 * 3}50`}
                >
                  <CourseTypeCard course={course} />
                </Grid>
              ))}
            </Grid>
          </Cards>
          <div className="flex justify-center mt-6 lg:mt-20">
            <Link to="/kontakt" data-sal-duration="650" data-sal="fade" data-sal-easing="ease-in" data-sal-delay="800">
              <Button style={{}} color="primary" variant="contained">
                MÁM ZÁUJEM
              </Button>
            </Link>
          </div>
        </Container>
      </CoursesSection>
    </>
  )
}

export default Courses
